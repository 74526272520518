@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
.mockup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding-top: 71.3%;
  background-size: contain; }
  .mockup .creation {
    position: absolute;
    display: flex;
    margin: auto;
    border-radius: 0 !important;
    width: 71.5%;
    background-size: cover;
    background-position: center center;
    opacity: 1; }
    .mockup .creation-wrap {
      background: linear-gradient(303.38deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5); }
  .mockup.greeting_cards .creation {
    top: 21.94%;
    left: 19.86%; }
  .mockup.photo_prints .creation {
    top: 15.9%;
    left: 17.7%; }
  .mockup.no-background {
    background-image: none; }

.loader-wrapper {
  box-shadow: none !important; }

.init-loading span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  margin-left: 2px; }

.init-loading span:nth-child(2) {
  animation-delay: .2s; }

.init-loading span:nth-child(3) {
  animation-delay: .4s; }

.loader {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99995;
  text-align: center; }

.loader .loader-position {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 25px; }

.loading-messages {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  font-weight: regular; }

.snapfile-logo {
  width: 4rem;
  height: 4rem; }

.snapfile-logo {
  animation-name: rotate;
  animation-duration: 1000ms;
  animation-iteration-count: infinite; }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes blink {
  0% {
    opacity: 1; }
  20% {
    opacity: 0; }
  100% {
    opacity: 1; } }
