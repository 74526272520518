.container {
  height: 70px;
  margin-right: -16px;
  margin-left: -16px;
  position: relative; }
  .container .fixed-bar {
    position: fixed;
    display: flex;
    justify-content: left;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    background-color: #333333;
    z-index: 999999; }
  .container .click {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2; }
  .container .back-btn {
    height: 40px;
    width: 40px;
    background: #ffffff;
    border-radius: 50%;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 24px;
    margin-left: 16px;
    background-image: url("/static/images/ic_arrow_back_24px.svg");
    background-repeat: no-repeat;
    background-position: center; }

.promotion {
  color: #ffc45e;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 3px;
  text-decoration: underline;
  z-index: 1; }
